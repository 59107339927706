import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Row, Col } from "react-bootstrap"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import ConnectSidebar from "../../components/layout/connect-sidebar"
import Iframe from "react-iframe"
import useGCLID from "../../hooks/useGCLID"

const NewsletterPage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Join Our Email List`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect">Connect with Us</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect/newsletter">Join Our Email List</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar></ConnectSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Join Our Email List</h1>
            <p>
              Please join our email list for the latest news about Walpole
              Outdoors. Be the first to know about new product introductions,
              updated services and seasonal specials. Your email address will be
              kept for our use only and won’t be shared with others.
            </p>
            <hr />
            <Iframe
              url={useGCLID(`https://www.tfaforms.com/4865964`, "tfa_2")}
              frameBorder="0"
              className="newsletterFramePage"
            />
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default NewsletterPage
